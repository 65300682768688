/* FONTS */
@font-face {
  font-family: 'christmas';
  src: url('fonts/winter-city-webfont.woff2') format('woff2'),
      url('fonts/winter-city-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'letter';
  src: url('fonts/baby_doll-webfont.woff2') format('woff2'),
      url('fonts/baby_doll-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* END OF FONTS */


/* MAIN DIVS */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  background-color: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  display: flex;
  min-height: 100vh;
  justify-content: space-evenly safe;
  min-height: 100%;
}

#mainContent {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Center horizontally */
  justify-content: flex-start; /* Start from the top */
  min-height: 100%; /* Ensure it takes up the full height of the screen */
  position: relative;
  z-index: 1;
}

input {
  font-size: 20px;
}

.login, .registration, .forgot {
  margin-left: 50px;
}

.login, .forgot, .registration, #addGift, .noGifts {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Center form elements horizontally */
  justify-content: center; /* Center the items vertically if needed */
  padding: 20px;
  background-color: rgba(5, 125, 3, 0.75); /* Semi-transparent background */
  border-radius: 10px;
  width: 100%;  /* Ensure it takes up full available width */
  max-width: 400px;  /* Limit the width of the form */
  margin-top: 20px;  /* Ensure it has spacing above */
}

#addGift, .noGifts {
  color: white;
}

#addGift h3 {
  margin-left: 5px;
}

.status {
  width: 400px;
  color: white;
  text-shadow: 2px 2px #000000;
  text-align: center;
  margin-left: -30px;
}

#homeGift {
  position: absolute;
  display: none;
  background-image:  url('assets/letter.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 390px;
  width: 300px;
  left: 400px;
  margin-left: 15px;
  font-family: letter;
  font-size:larger;
  padding-top: 30px;
  padding-left: 3px;
}

#homeGift p:nth-child(5) {
  margin-left: 10px;
}

#homeGift img {
  width: 100px;
  height: 100px;
  position: absolute;
  border: solid white 2px;
  right: -20px;
  bottom: -60px;
}

#addGift {
  margin-left: 50px;
}

[id^=uGift] {
  width: 95vw;
  color: white;
  font-size: x-large;
  border: solid white 1px;
}

[id^=uGift]:nth-child(even) {
  background-color: green;
}   

[id^=uGift]:nth-child(odd) {
  background-color: red;
}   

/* END OF MAIN DIVS */


/* LINKS */
.userLinks {
  color: white;
  margin-left: 20px;
  margin-top: 5px;
  font-family: 'Roboto';
}

a:link,a:visited {
  color: white;
  text-decoration: none;
  text-shadow: 2px 2px #000000;
}

a:hover,a:active {
  color: red;
}

 a.returnHome:link, a.returnHome:visited  {
  text-decoration: underline;
}
/* END OF LINKS */


/* MAIN USER MENU */
#userMenu {
  display: inline;
}

#userMenu ul li {
  list-style-type: none;
}

.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.burger1 {
  background-color: red;
}

.burger2 {
  background-color: white;
}

.burger3 {
  background-color: green;
}
/* END OF MAIN USER MENU


/* HEADING STYLES */
header {
  position: relative; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;  /* Ensure it appears above the content but below the background */
}

h1 {
  font-family: christmas;
  font-size: 2.25em;
  margin: 0;                      /* Remove default margin of h1 */
  color: white !important;
  text-shadow: 3px 3px #000000;
}

h2 {
  font-size: 2em;
  font-family: christmas;
  color: white;
}

#btnHome {
  max-width: 50px;
  height: auto;  
}
/* END OF HEADING STYLES */


/* HTML TAG STYLES */
label {
  font-family: Monaco, Courier, monospace;
  font-size:x-large;
  color: white;
  text-shadow: 2px 2px #000000;
}
/* END OF HTML TAG STYLES */


/* BUTTON STYLES */
.buttonContainer {
  display: flex;               
  justify-content: center;     
  align-items: center;         
  height: 100%; 
  width: 100%;               
}

.giftContainer {
  justify-content: left;
}

button {
  width: 200px;
  height: 80px;
  font-size:x-large;
  color: white;
  background-color: red;
  border-color: green;
}

button:hover {
  background-color: white;
  color: green;
  cursor: pointer;
}

.submit-btn:disabled, .submit-btn:disabled:hover, button:disabled {
  min-width: var(--buttonWidth);
  cursor: not-allowed;
  color: white;
  background-color: red;
  border-color: green;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.delete-btn, .purchase-btn {
  font-size: small;
  width: fit-content;
  height: fit-content;
  margin: 5px;
  border-color: white;
}

.logout-btn {
  font-size: medium;
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 100;
}
/* END OF BUTTON STYLES */


/* MEDIA QUERIES */
/* MOBILE PORTRAIT */
@media (max-width: 320px) {
  .login, .registration, .forgot {
    margin-left: 10px;
  }
}

/* MOBILE LANDSCAPE */
@media (min-width: 480px) {
  .login, .registration, .forgot {
    margin-left: 10px;
  }

  .userHeading {
    position: relative;
    left: 75px;
    font-size: xx-large;
  }
}


/* TABLET AND COMPUTER */
@media (min-width: 700px) {
  #homeGift {
    display: block;
    width: 300px;  /* Set a fixed width for the GiftLetter */
}

  #giftList {
    margin-left: 100px;
    width:95%;
  }

  [id^=uGift] {
    width: 75%;
  }

  .hamburger {
    display: none;
  }

  .userHeading {
    top:5px;
  }

  #addGift {
    max-width: 600px;
    margin-left: 100px;
    margin-top: 10px;
  }
}
/* END OF MEDIA QUERIES */